import { navigationItems } from '@mfe/core-navigation';
import { registerApp } from '@mfl/framework';

const rootRoute = '/';
const signatureEditRoute = '/signature';
const signaturesRoute = '/signatures';
const instructionsRoute = '/instructions';
const profileRoute = '/profile';

export async function register() {
  await registerApp('employee-portal-signatures', [
    rootRoute,
    signaturesRoute,
    instructionsRoute,
    signatureEditRoute,
    profileRoute,
  ]);

  navigationItems.register({
    route: signaturesRoute,
    key: 'SIGNATURES',
    text: 'Signatures',
    fontAwesomeIcon: 'file-signature',
  });
}
